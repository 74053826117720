// Define variables
$color-primary: var(--color-primary);
$transition: var(--transition);

.experience {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    // Media Queries (Large Devices)
    @media screen and (max-width: 1400px) {
      gap: 1rem;
    }

    // Media Queries (Medium Devices)
    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    // Media Queries (Small Devices)
    @media screen and (max-width: 600px) {
      gap: 1rem;
    }
  }

  &__frontend, &__backend {
    background: transparent;
    border: 1px solid var(--color-primary);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    transition: var(--transition);
    h3 {
      text-align: center;
      margin-bottom: 2rem;
      color: var(--color-primary);
    }

    // Media Queries (Large Devices)
    @media screen and (max-width: 1400px) {
      padding: 2rem 3rem;
      border-radius: 2rem;
    }

    // Media Queries (Medium Devices)
    @media screen and (max-width: 1200px) {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
    }

    // Media Queries (Small Devices)
    @media screen and (max-width: 600px) {
      .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
      }
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;

    // Media Queries (Medium Devices)
    @media screen and (max-width: 1200px) {
      padding: 1rem;
    }

    // Media Queries (Small Devices)
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }

  &__details {
    display: flex;
    gap: 1rem;

    &-icon {
      margin-top: 6px;
      color: var(--color-primary);
    }

    // Media Queries (Small Devices)
    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }
  }
}
