// General Styles
.footer {
  &__container {
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
  }

  // Social Media Styles
  &__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    position: relative;

    // Media Queries (Large Devices)
    @media screen and (min-width: 1200px) {
      left: 40px;
    }

    // Media Queries (Medium Devices)
    @media screen and (min-width: 768px) {
      gap: 0.8rem;
      position: fixed;
      z-index: 10;
      width: 40px;
      flex-direction: column;
      align-items: center;
      bottom: 0px;
      left: 20px;
      right: auto;
      margin-bottom: 0;
    }

    a {
      color: var(--color-primary);
      padding: 0.8rem;
      display: flex;
      font-size: 1.5rem;

      &:hover {
        color: var(--color-light);
      }

      // Media Queries (Medium Devices)
      @media screen and (min-width: 768px) {
        padding: 0;
        font-size: 1.2rem;
      }
    }
    // Media Queries (Small Devices)
    @media screen and (max-width: 600px) {
      margin-bottom: 2.6rem;
    }
  }

  &__socials::after {
    display: none;

    // Media Queries (Medium Devices)
    @media screen and (min-width: 768px) {
      display: block;
      content: "";
      width: 1px;
      height: 6rem;
      background: var(--color-primary);
    }
  }

  // Copyright Styles
  &__copyright {
    margin-bottom: 4rem;
    color: var(--color-light);
  }
}
