.testimonial {
  &__container {
    width: 40%;
    padding-bottom: 4rem;

    // Media Queries (Large Devices)
    @media screen and (max-width: 1024px) {
      width: 60%;
    }

    // Media Queries (Small Devices)
    @media screen and (max-width: 600px) {
      width: var(--container-width-sm);
    }
  }

  &__slide {
    background: transparent;
    border: 1px solid var(--color-primary);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;

    h3 {
      font-size: 1rem;
      color: var(--color-white);
    }

    h4 {
      font-size: 0.75rem;
      margin-bottom: 1rem;
    }

    &-avatar {
      width: 4rem;
      aspect-ratio: 1/1;
      overflow: hidden;
      border-radius: 50%;
      margin: 0 auto 1rem;
      border: 0.4rem solid var(--color-primary-variant);
    }

    &-review {
      color: var(--color-light);
      font-weight: 300;
      display: block;
      width: 80%;
      margin: 0 auto 0;
      font-size: 0.85rem;

      // Media Queries (Small Devices)
      @media screen and (max-width: 600px) {
        width: var(--container-width-sm);
      }
    }
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
  }
}
